import { useEffect, useState, useContext } from "react";
import { useIntl } from 'react-intl';
import httpClient, { httpClientUtils } from "../../../utils/httpClient";
import { appIds } from "./constants";
import I18nContext from '../../IntlProvider/IntlContext';

const formatAppDrawerItems = (userApplications, intl) => {
  const descriptionMappingByItemId = {
    [appIds.USER_MANAGER]: "global.appDrawer.userManager.description",
    [appIds.WELCOME_PAGE]: "global.appDrawer.welcomePage.description",
    [appIds.OGONE]: "global.appDrawer.ogone.description",
    [appIds.PASS_ACQUIRING]: "global.appDrawer.passAcquiring.description",
    [appIds.DEVELOPER]: "global.appDrawer.developer.description",
    [appIds.SALESFORCE_COMMUNITY]:
      "global.appDrawer.salesForceCommunity.description",
  };

  const nameMappingByItemId = {
    [appIds.USER_MANAGER]: "global.appDrawer.userManager.name",
    [appIds.WELCOME_PAGE]: "global.appDrawer.welcomePage.name",
    [appIds.OGONE]: "global.appDrawer.ogone.name",
    [appIds.PASS_ACQUIRING]: "global.appDrawer.passAcquiring.name",
    [appIds.DEVELOPER]: "global.appDrawer.developer.name",
    [appIds.SALESFORCE_COMMUNITY]: "global.appDrawer.salesForceCommunity.name",
  };

  return userApplications.map((userApplication) => {
    return {
      ...userApplication,
      title: intl.formatMessage({ id: `${nameMappingByItemId[userApplication.id]}`, defaultMessage: userApplication.name}),
      description: intl.formatMessage({ id: `${descriptionMappingByItemId[userApplication.id]}`, defaultMessage: `${userApplication.name} description`}),
      url: userApplication.link,
    };
  });
};

export const useFetchAppDrawerItems = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [appDrawerItems, setAppDrawerItems] = useState([]);
  const { locale } = useContext(I18nContext);
  const intl = useIntl()
  useEffect(() => {
      setIsLoading(true);
      httpClient.get(
        `${httpClientUtils.getSetting("myProfileApiPath")}/users/info/apps`,
        async (response) => {
          await setAppDrawerItems(formatAppDrawerItems(response, intl));

        },
        (error) => {
          console.error(
            "Something went wrong while fetching app drawer items: ",
            error
          );
        }
      );
      setIsLoading(false);
    }
  , [locale, intl]);

  return { isLoading, appDrawerItems };
};
